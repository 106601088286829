
import { createSlice } from "@reduxjs/toolkit";

const DetailsSlice = createSlice({
    name: "DetailsSlice",
    initialState: {
        pageName: ""
    },
    reducers: {
        pageName: (state, action) => {
            state.pageName = action.payload
        }
    }
})
const { actions, reducer } = DetailsSlice;
export const { pageName } = actions;
export default reducer