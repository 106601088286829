import { useNavigate } from "react-router-dom"
import "./About.css"
import { useTranslation } from "react-i18next"

const About = () => {
    const { t, i18n } = useTranslation()
    var media = window.matchMedia("(max-width: 768px)")
    var ipad = window.matchMedia("(min-device-width : 768px) and (max-device-width : 1024px) ")
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate("/AboutUs")
    }
    const handleBlog = () => {
        navigate("/blog")
    }
    return (
        <>
            <div className={`container-fluid about-us m-0 ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-md-5">
                        <p>
                            {t("fourDecadeDesc")}

                            {/* Established in 1978, al Jabriya is a{media.matches == true && ipad.matches == true ? <></> : <br />}
                            family business committed to{media.matches == true && ipad.matches == true ? <></> : <br />}
                            delivering exceptional solutions in{media.matches == true && ipad.matches == true ? <></> : <br />}
                            design, fitout and office furnishing... */}
                        </p>
                        <div className="about-arrow-con" onClick={handleNavigate}>
                            <h4>{t("about")}</h4>
                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="87" viewBox="0 0 38 87" fill="none">
                                <path d="M1 86L36.1333 43.5L1 1" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>
                    </div>
                    <div className="col-md-7 px-0">
                        <div className="read-more">
                            <h3>{t("office")}</h3>
                            <h6>{t("blog")}</h6>
                            <div className="about-arrow-con" onClick={handleBlog}>
                                <h4>{t("read")}</h4>
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="87" viewBox="0 0 38 87" fill="none">
                                    <path d="M1 86L36.1333 43.5L1 1" stroke="#434485" strokeWidth="2" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About