import "./Career.css"
import { Header, Footer } from "../../Sections"
import { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"
const Career = () => {
    const [apply_of, serApply_of] = useState()
    const [full_name, setFull_name] = useState()
    const [file, setFile] = useState()
    const [email, setEmail] = useState()
    const { t, i18n } = useTranslation()
    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post("https://api.al-jabriya.com/api/send_email", {
            apply_of,
            full_name, file, email
        },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>Career</title>
                <meta name="description" content="Join Al-jabriya's dynamic team and explore exciting career opportunities. We value talent, innovation, and dedication, offering a supportive environment for professional growth." />
            </Helmet>
            <Header page="sample" />
            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project link-career ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("career")} </p>
            </div>
            <div className={`container-fluid career-con ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-md-6 o-2">
                        <div className="Vacancies-form">
                            <h3>{t("JoinUs")}</h3>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="mb-3">
                                    <label htmlFor="apply-for" className="form-label">{t("Applyfor")}</label>
                                    <input type="text" id="apply-for" className="form-control" onChange={(e) => serApply_of(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="full-name" className="form-label">{t("FullName")} ({t("Required")})</label>
                                    <input type="text" id="full-name" className="form-control" required onChange={(e) => setFull_name(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">{t("Email")} ({t("Required")})</label>
                                    <input type="email" id="email" className="form-control" required onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="file" className="form-label">
                                        <div className="flex-aswad-col">
                                            {t("Uploadyourresume")}
                                            <span>{t("ChooseFile")}</span>
                                        </div>
                                    </label>
                                    <input type="file" id="file" className="form-control" onChange={(e) => setFile(e.target.files[0])} />
                                </div>
                                <div className="submit-btn">
                                    <button>{t("Send")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 o-1">
                        <div className="Vacancies-text">
                            <h3>{t("Vacancies")}</h3>
                            <p>{t("SalesManager")}</p>
                            <p>{t("SalesExecutive")}</p>
                            <p>{t("InteriorDesigner")}</p>
                            <p>{t("GraphicDesigner")}</p>
                            <p>{t("Distributor")}</p>
                        </div>
                    </div>
                </div>

            </div>
            <Footer background="none" />
        </>
    )
}

export default Career