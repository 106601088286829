import "./Brands.css"
import { BrandsHero, BrandsLogo, Categories, Footer, Header } from "../../Sections"
import { useEffect, useState } from "react"
import catImg from "../../Assets/Images/1.png"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { useAnimation, motion } from "framer-motion"
import { useDispatch } from "react-redux"
import { pageName } from "../../redux/DetailsSlice"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"

const Brands = () => {
    const [logos, setLogos] = useState()
    const [logoId, setLogoId] = useState()
    const [brands, setBrands] = useState()
    const [loading, setLoading] = useState(true)
    const [brandsSelect, setBrandsSelect] = useState()
    const [CategoriesSelect, setCategoriesSelect] = useState()
    const [brandTitle, setBrandTitle] = useState()
    const [products, setProducts] = useState()
    const [lastCat, setLastCat] = useState()
    const navigate = useNavigate()
    const projectsAnimation = useAnimation()
    const [isSearch, setIsSearch] = useState(false)
    const dipatch = useDispatch()
    const element = document.getElementById("Categories");
    const { t, i18n } = useTranslation()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    useEffect(() => {
        dipatch(pageName("sample"))
        axios.get("https://api.al-jabriya.com/api/brand/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                projectsAnimation.start({
                    y: 0,
                    opacity: 1,
                    transition: {
                        duration: .8
                    }
                })
                setLogos(res.data.data.brand)
            })
            .catch(err => {
                console.log(err)
            })
        axios.get(`https://api.al-jabriya.com/api/categories_brand/all?brand_uuid=${logoId != null ? logoId : ""}`, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setBrands(res.data.data.categoryBrands)
                setLoading(false)
                projectsAnimation.start({
                    y: 0,
                    opacity: 1,
                    transition: {
                        duration: .8
                    }
                })
            })
            .catch(err => {
                setLoading(true)
            })
    }, [logoId, i18n.language])
    const handleId = (ID) => {
        setLogoId(ID)
        element.scrollIntoView();
    }
    const handleNavigate = (ID, title) => {
        navigate(`/product/${ID}`, {
            state: {
                id: ID,
                brandTitle: brandsSelect != null && brandsSelect[1],
                productID: title,
                link: brandsSelect != null && brandsSelect,
                categoryBrandUuid: isSearch == true ? CategoriesSelect : null,
                brand_uuid: isSearch && brandsSelect != null ? brandsSelect[0] : null
            }
        }
        )
    }
    const selectOptionBrands = logos && logos.map(brand => (
        <option value={[brand.uuid, brand.title]}>{brand.title}</option>
    ))
    const selectOptionCategories = brands && brands.map(Category => (
        <option value={Category.uuid} >{Category.title.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</option>
    ))
    const handleBrandsClick = () => {
        setIsSearch(true)
        console.log(CategoriesSelect);
        axios.post(`https://api.al-jabriya.com/api/product/all`, {
            category_brand_uuid: CategoriesSelect,
            brand_uuid: brandsSelect
        }, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                console.log(res)
                setProducts(res.data.data.products)
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        axios.post(`https://api.al-jabriya.com/api/product/all`, {
            category_brand_uuid: CategoriesSelect,
            brand_uuid: brandsSelect
        }, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                console.log(res)
                setProducts(res.data.data.products)
            })
            .catch(err => {
                console.log(err)
            })
    }, [i18n.language])
    // useEffect(() => {
    //     axios.get("https://apl.al-jabriya.com/apl/logo/all/brand")
    //         .then(res => {
    //             console.log(res)
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })
    // }, [])
    return (
        <>
        <Helmet>
             <title>Brands</title>
             <meta name="description" content="Al-jabriya offers a range of top-quality, modern office furniture from famous international brands. Our choices keep up with the latest office design trends and technological innovations." />
        </Helmet>

            <Header />

                <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                    <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("products")}</p>
                </div>
                <div className={`container-fluid brands-con dddd ${i18n.language == "ar" && "ar"}`}>
                    <div className="text-con-brands">
                        <h2>{t("UnrivaledSelection")}</h2>
                        <p>{t("UnrivaledSelectionDesc")}</p>
                        <div className={`container-fluid  categories select-new ${i18n.language == "ar" && "ar"}`}>
                            <div className="container-fluid px-0 m-0">
                                <div
                                    className="row row-sm-categories row-cols-1 row-cols-md-3 row-cols-lg-3 ">
                                    <div className="col">
                                        <div className="Categories-card">
                                            <select onChange={(e) => setCategoriesSelect(e.target.value)}>
                                                <option selected value="">{t("Categories")}</option>
                                                {selectOptionCategories}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="Categories-card">
                                            <select className="select-2" onChange={(e) => {
                                                setBrandsSelect(e.target.value.split(",")[0])
                                            }}>
                                                <option selected value="">{t("brands")}</option>
                                                {selectOptionBrands}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="Categories-card">
                                            <button className="search-btn" onClick={handleBrandsClick}>{t("Search")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className={`select-flex ${i18n.language == "ar" && "ar"}`}>
                            <select onChange={(e) => setCategoriesSelect(e.target.value)}>
                                <option selected value="">{t("Categories")}</option>
                                {selectOptionCategories}
                            </select>
                            <select onChange={(e) => {
                                setBrandsSelect(e.target.value.split(",")[0])
                                console.log(e.target.value)
                            }}>
                                <option selected value="">{t("brands")}</option>
                                {selectOptionBrands}
                            </select>
                            <a className="search-btn" onClick={handleBrandsClick}>{t("Search")}</a>
                        </div> */}
                    </div>
                </div>
            {/* <BrandsHero brands={logos} Categories={brands} /> */}
            {/* <div className={"container-fluid logos-con"}>
                <h2 className="h2-line">Brands</h2>
                <motion.div
                    initial={{
                        opacity: 0,
                        y: 200
                    }}
                    animate={projectsAnimation}
                    className="brands-logo-con">

                    {logos && logos.map(logo => (
                        <img src={logo.image} className="logo-brands-img" key={logo.uuid} onClick={() => handleId(logo.uuid)} />
                    ))}
                </motion.div>
            </div> */}
            <div className={`container-fluid  categories ${i18n.language == "ar" && "ar"}`}>
                <div className="container-fluid px-0 m-0">
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: 200
                        }}
                        animate={projectsAnimation}
                        className="row row-sm-categories row-cols-1 row-cols-md-2 row-cols-lg-3 ">

                        {products && products.length > 0 ? products.map(brand => (
                            <div className="col">
                                <div className="Categories-card" onClick={() => handleNavigate(brand.uuid, brand.title)}>
                                    <div className="img-brands-imges"
                                        style={{
                                            backgroundImage: `url(${brand.image})`
                                        }}></div>
                                    <p>{brand.title}</p>
                                </div>
                            </div>

                        )) : <p className="nothing">This item isn’t available in this brand.</p>}

                    </motion.div>
                </div>
            </div>

            <Footer background="none" />
        </>
    )
}

export default Brands