import "./FourDecade.css"
import { useTranslation } from "react-i18next"
const FourDecade = () => {
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className={`container-fluid four-decade ${i18n.language == "ar" && "ar"}`}>
                <h2 className="title">{t("fourDecadOf")}</h2>
                <div className="row row-decade">
                    <div className="col-md-6 ">
                        <div className="img _contain">
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text">
                            <p>
                                {t("fourDecade")}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FourDecade