import "./Message.css"
import AboutImg from "../../../Assets/Images/generalmangment.JPG"
import { useTranslation } from "react-i18next"
const Message = () => {
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className={`container-fluid message-main-con ${i18n.language == "ar" && "ar"}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text">
                                <h5>{t("generalManagerTitle")}</h5>
                                <h4>{t("generalManagerSubTitle")}</h4>
                                <p>{t("generalManagerDesc")}

                                </p>
                                <h6>
                                    {t("Sincerely")}, <br />
                                    <span> {t("generalManagerName")}</span><br />
                                    {t("GeneralManager")}
                                </h6>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img">
                                <img src={AboutImg} alt="about image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Message