import { useTranslation } from "react-i18next"
import "./WhereDesign.css"

const WhereDesign = () => {
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className={`container-fluid where-design ${i18n.language == "ar" && "ar"}`}>
                <h2 >{t("WhereDesign")}</h2>
                <div className="text">
                    <p>{t("whereDesDec")}</p>
                </div>
            </div>
        </>
    )
}

export default WhereDesign