import { useEffect, useRef, useState } from "react"
import "./InternationalRecogition.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import Img from "../../../Assets/Images/1.png"
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

const InternationalRecogition = ({ images }) => {
    const sliderRef = useRef()
    const [isVisible, setIsVisible] = useState(true)
    const element = document.getElementById("slider");
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className={`container-fluid main-international-recogition ${i18n.language == "ar" && "ar"}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 o-awards-2">
                            <div className="Awards-text">
                                <h2>{t("InternationalRecognition")}</h2>
                                <p>{t("InternationalRecognitionDesc")}</p>
                                <div className="flex-aswad-start">
                                    <h6>{t("ViewourAwards")}</h6>
                                    <svg onClick={() => {
                                        setIsVisible(!isVisible)
                                        element.scrollIntoView();
                                    }} xmlns="http://www.w3.org/2000/svg" width="55" height="33" viewBox="0 0 55 33" fill="none">
                                        <g filter="url(#filter0_d_80_1453)">
                                            <path d="M5 1L27.5 23.5L50 1" stroke="#CCCCCC" strokeWidth="2" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_80_1453" x="0.292969" y="0.292969" width="54.4141" height="32.6211" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_80_1453" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_80_1453" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="international-recogition-slider container-fluid" id="slider" style={{
                height: isVisible ? "100%" : "0vh",
                paddingTop: isVisible ? "96px" : "0px",
                paddingBottom: isVisible ? "96px" : "0px",
            }}>
                <Swiper
                    style={{
                        height: isVisible ? "100%" : "0vh",
                        transition: ".3s",
                        opacity: isVisible ? "1" : "0"
                    }}
                    modules={[Navigation]}

                    navigation
                    spaceBetween={50}
                    breakpoints={{
                        640: {
                            slidesPerView: 1
                        },
                        768: {
                            slidesPerView: 3
                        },
                    }}
                >
                    {images && images.map(item => (
                        <SwiperSlide>
                            <div className="international-recogition-card">
                                <img src={item.image} className="img-fluid" alt="image" />
                            </div>
                        </SwiperSlide>
                    ))}
                    {/* <SwiperSlide>
                        <div className="international-recogition-card">
                            <img src={Img} className="img-fluid" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="international-recogition-card">
                            <img src={Img} className="img-fluid" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="international-recogition-card">
                            <img src={Img} className="img-fluid" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="international-recogition-card">
                            <img src={Img} className="img-fluid" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="international-recogition-card">
                            <img src={Img} className="img-fluid" />
                        </div>
                    </SwiperSlide> */}

                </Swiper>
            </div>
        </>
    )
}

export default InternationalRecogition